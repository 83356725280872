import React, { Component } from 'react'
// import { withNamespaces } from 'react-i18next'
import { Link, StaticQuery, graphql } from 'gatsby'
import PostCover from 'components/PostCover'
import './style.scss'

const RelatedPosts = ({title=null, subtitle=null, posts}) => {

  const items = [];
  if (posts.edges.length >0) {
    posts.edges.forEach(post => {
      items.push({
          id: post.node.id,
          cover: post.node.frontmatter.cover,
          title: post.node.frontmatter.title,
          category: post.node.frontmatter.category,
        //   shortDesc: post.node.frontmatter.shortDesc,
          excerpt: post.node.excerpt,
          date: post.node.fields.date,
          slug: post.node.fields.slug,
        //   position: post.node.frontmatter.position,
          // templateKey: postEdge.node.frontmatter.templateKey,
      });
  });
  }
    const slides = posts? items.map((item, i=0) => {
      ++i;
      return (
          <div className="media mb-3" key={i} >
            
                <div className="align-self-start mr-3 image-cover">
                    <Link to={item.slug}>
                    <PostCover postNode={item} coverHeight={56} />
                    </Link>
                </div>
                <div className="media-body">
                    
                    <p className="media-text"><Link to={item.slug}>{item.title}</Link></p>
                    
                </div>
            
          </div>
      )
    })
    : <p>Chưa có dịch vụ nào</p>

    return (
        <div className="container mt-3" id="related">
            <p className="media-title text-uppercase">{title}</p>
            <p>{subtitle}</p>
            <br />
            {slides}
        </div>
    )
  }


// export default withNamespaces('translation')(SectionService)
export default RelatedPosts;

export const queryNewestPost = graphql`
    fragment NewestPostsFragment on Query {
        newestPosts: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "hainamer"}}}, limit: 5, sort: {fields: [frontmatter___date], order: DESC}) {
            edges {
            next {
                id
            }
            node {
                fields {
                slug
                date
                }
                id
                excerpt
                timeToRead
                frontmatter {
                title
                cover
                category
                date
                }
            }
            }
        }
  }`

  export const queryNewestProduct = graphql`
    fragment NewestProductsFragment on Query {
        newestProducts: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "product"}}}, limit: 5, sort: {fields: [frontmatter___date], order: DESC}) {
            edges {
            next {
                id
            }
            node {
                fields {
                slug
                date
                }
                id
                excerpt
                timeToRead
                frontmatter {
                title
                cover
                category
                date
                }
            }
            }
        }
  }`

  export const queryNewestPricing = graphql`
    fragment NewestPricingsFragment on Query {
        newestPricing: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "pricing"}}}, limit: 5, sort: {fields: [frontmatter___date], order: DESC}) {
            edges {
            next {
                id
            }
            node {
                fields {
                slug
                date
                }
                id
                excerpt
                timeToRead
                frontmatter {
                title
                cover
                category
                date
                }
            }
            }
        }
  }`


